<div class="gwc-traveler">
	@if (traveler) {
		<section class="gwc-traveler__main">
			<header class="gwc-content-header">
				<span class="gwc-traveler__header">
					<img [src]="traveler.avatar" />
					<h1>{{ traveler.friendly_name }}'s applications</h1>
				</span>
			</header>
			
			@for (application of traveler.applications; track application.uuid) {
				<div class="gwc-traveler-app">
					<div class="gwc-card"> 
						<div class="gwc-traveler-app__main">
							<div class="gwc-traveler-app__info">
								<div class="gwc-traveler-app__info-label">
									{{ application.product.label }}
								</div>
	
								@if (application.progress > 0 && (application.product.subtype !== 'idp' || application.status !== 'completed' || !application.packet_approved)) {
									<div class="gwc-traveler-app__info-progress">
										<mat-progress-bar class="gwc-travelers-item__progress-bar"
											mode="determinate" 
											[value]="application.progress"></mat-progress-bar>
										<span>{{ application.progress }}%</span>
									</div>
								}
	
							</div>
							@if (application.status === 'new') {
								<button mat-flat-button
									[routerLink]="['app', application.uuid]"
									class="gwc-travelers-item__btn"
									color="primary">
									Start Application
								</button>
							} @else if (!['shipped_out', 'delivered', 'documents_processing', 'dropped_off', 'reviewed', 'processed', 'shipped_in'].includes(application.status) || application.manifest?.status === 'qa_error') {
								<button mat-stroked-button
									[disabled]="application.status === 'canceled'"
									[routerLink]="['app', application.uuid]"
									class="gwc-travelers-item__btn"
									color="primary">
									@if (application.status === 'canceled') {
										Cancelled
									} @else if (application.progress >= 0 && application.progress < 100) {
										Continue Application
									} @else {
										Edit Application
									}
								</button>
							}
							<div class="gwc-traveler-app__icon gwc-traveler-app__icon--{{ application.product.type }}">
								@if (application.product.type === 'visa') {
									<span class="fi fi-{{application.product.country_code?.toLowerCase()}}"></span>
								} @else {
									<svg>
										<use [attr.xlink:href]="'assets/svg/shared.svg#' + application.product.type"/>
									</svg>
								}
							</div>
						</div>
						@if(application.product.type === 'idp' && application.manifest?.license?.support) {
							<div class="gwc-traveler-app__idp-support">
								If you have any questions, feel free to contact the AAA office via <a alt="support email" href="mailto:{{ application.manifest?.license?.support }}">{{ application.manifest?.license?.support }}</a>
							</div>
						}
					</div>

					@if (!['new', 'pending', 'canceled'].includes(application.status)) {
						<gwc-status-message [application]="application"
							[swapping]="swapping === application.uuid"
							[submitting]="submitting.includes(application.uuid)"
							(callback)="messageCallback($event, application)">
						</gwc-status-message>
					}
				</div>
			}

			<!-- <div *ngIf="video"
				class="gwc-traveler-dialog">
				<div class="gwc-traveler-dialog__wrapper">
					<div class="gwc-traveler-dialog__body">
						<div class="gwc-traveler-dialog__header">
							<img src="assets/svg/icon-gov.svg" />
							Your application is being generated
							<button mat-icon-button
								(click)="video = null"
								class="gwc-traveler-dialog__close">
								<svg>
									<use href="assets/svg/shared.svg#close"/>
								</svg>
							</button>
						</div>
						<video *ngIf="true" 
							autoplay="autoplay"
							id="video" controls>
							<source 
								*ngIf="true" 
								src="assets/videos/passport_renewal.mp4" 
								type="video/mp4"/>
							<source 
								ng-if="$ctrl.product != 'passport-renewal' && $ctrl.product != 'second-passport' && $ctrl.product != 'name-change'" 
								src="/videos/new_passport.mp4" 
								type="video/mp4"/>
						</video>
					</div>
					<div class="gwc-traveler-dialog__btm-bar">
						<div *ngIf="traveler.applications[0].status === 'processing'">
							<span>Submitting to U.S. Department of State </span><br/>
							{{ message }}...
						</div>
						<div *ngIf="traveler.applications[0].status === 'completed'">
							Your application is ready to be reviewed.
						</div>
						<gwc-button color="primary"
							type="button"
							(callback)="openApplicationPreview(this.video)"
							[fullwidth]="true"
							[disabled]="submitting.includes(video.uuid)"
							[spinner]="submitting.includes(video.uuid)">
							Preview
						</gwc-button>
					</div>
				</div>
			</div> -->

			@if (preview) {
				<div class="gwc-traveler-dialog">
					<div class="gwc-traveler-dialog__wrapper">
						<div class="gwc-traveler-dialog__body gwc-traveler-dialog__body--full">
							<div class="gwc-traveler-dialog__header gwc-traveler-dialog__header--shadow">
								<img src="assets/svg/icon-gov.svg" />
								Review your Personalized Application Packet
								<button mat-icon-button
									(click)="preview = null"
									class="gwc-traveler-dialog__close">
									<svg>
										<use href="assets/svg/shared.svg#close"/>
									</svg>
								</button>
							</div>
							<div class="gwc-traveler-dialog__preview">
								<img src="{{api}}application/{{preview.uuid}}/download" />
							</div>
						</div>
						<div class="gwc-traveler-dialog__btm-bar gwc-traveler-dialog__btm-bar--center">
							<gwc-button color="primary"
								style="margin: 0 10px; width: 205px;"
								type="button"
								color="#B3B3B3"
								(click)="closePreview(false)"
								[fullwidth]="true">
								I Need to Make Corrections
							</gwc-button>
							<gwc-button color="primary"
								type="button"
								style="margin: 0 10px; width: 205px;"
								(click)="closePreview(true)"
								[fullwidth]="true">
								Everything Looks Good!
							</gwc-button>
						</div>
					</div>
				</div>
			}

		</section>
	} @else {
		<section class="gwc-traveler__empty"></section>
	}
	<gwc-itinerary-widget [order_uuid]="params['order_uuid']"></gwc-itinerary-widget>
</div>
